@import '../variables/variables';

.form-section {
  margin-top: 2 * $spacing-big;

  &:first-of-type {
    margin-top: $spacing-big;
  }

  &.last {
    margin-bottom: $spacing-small;
  }

  .direct-debit-info{
    margin-bottom: $spacing-big;
  }

  .how-we-use-your-info {
    margin-top: $spacing-big;
  }

  .next-button-section {
    margin-bottom: $spacing-big;
  }

  h2 {
    border-bottom: 1px solid $color-brand-primary;
    margin-bottom: $spacing-small;
  }

  input:disabled {
    background-color: $color-grey-light;
    border-color: $color-grey-dark;
    opacity: $disabled-opacity;
    pointer-events: none;
  }

  &.disabled {
    opacity: $disabled-opacity;
    pointer-events: none;
  }
}
