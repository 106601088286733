@import '~deko-common-components/lib/styles/mixins';

.tooltip {
  .button {
    position: relative;

    .icon {
      .img {
        width: 27px;
        height: 27px;
      }
    }

    .disabled & {
      cursor: default;
    }
  }

  .message {
    cursor: auto;
    display: none;
    -webkit-transform: translate3d(0, 0, 0);
  }

  &.visible {
    .message {
      display: block;
    }
  }
}
