@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.close-button-outer-wrapper {
  display: inline-flex;
  justify-items: center;
  width: 1.5em;
  height: 1.5em;
  position: relative;
  margin-top: 0;

  .close-button-wrapper {
    display: block;
    border-radius: 50%;
    padding-left: 6px;
    position: relative;
    width: 1.5em;
    height: 1.5em;

    .close-button {
      &::before,
      &::after {
        background-color: $color-black;
      }
    }
  }

  .close-button-outer-wrapper:focus, .close-button-wrapper:focus {
    outline: none;
  }

  .close-button-outer-wrapper:focus {
    .close-button-wrapper {
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}
