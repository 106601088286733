@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.radio-input {
  &.has-error {
    .label {
      display: none;

      &.error {
        display: inline;
      }
    }

    .radio-group.modern {
      border-color: $color-error;

      label {
        display: flex;
      }

      .radio-button {
        border-color: $color-error;
      }
    }

    .error {
      color: $color-error;
    }
  }
}

.has-tooltip .radio-group.modern.horizontal {
  width: calc(100% - 45px);
  border-right: none;
  border-radius: 2px 0 0 2px;
  float: left;

  .radio-button:last-of-type label {
    border-radius: 0;
  }
}

.counter-input .radio-group.modern {
  font-weight: bold;
}

.radio-group.modern {
  border: 1px $color-grey-light solid;
  border-radius: 2px;
  font-size: $font-size-the-base;
  font-weight: bold;

  input[type='radio'] {
    @include prefix(appearance, none, (webkit, moz));
    @include hiddenElementVisibleForScreenReaders();
    background: none;
    border: none;
    &:focus +label {
      border: 1px solid $color-brand-secondary;
    }
  }

  label {
    cursor: pointer;
    background-color: $color-white;
    color: $color-black;
    text-align: center;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    font-weight: 700;
    padding: 10px;

    &:hover {
      background-color: $color-brand-primary;
      color: $color-white;
    }
  }

  .checked label {
    background-color: $color-brand-secondary;
    color: $color-white;
  }

  &.horizontal {
    width: 100%;

    .radio-button {
      border: solid $color-grey-light;
      border-width: 0 0 0 1px;

      &:first-of-type {
        border: none;
      }

      &:first-of-type label {
        border-radius: 2px 0 0 2px;
      }

      &:last-of-type label {
        margin-right: 0;
        border-radius: 0 2px 2px 0;
      }
    }
  }

  &.vertical {
    .radio-button {
      border: solid $color-grey-light;
      border-width: 1px 0 0 0;

      &:first-of-type {
        border: none;
      }

      label {
        text-align: left;
        padding: 0 1em;
      }

      &:first-child label {
        border-radius: 2px 2px 0 0;
      }
      &:last-child label {
        border-radius: 0 0 2px 2px;
      }
    }
  }

  &.disabled {
    opacity: $disabled-opacity;
    border-color: $color-grey-dark;
    pointer-events: none;
    .radio-button.disabled {
      background-color: $color-grey-light;
      border-color: $color-grey-dark;
      label {
        background-color: $color-grey-light;
        border-color: $color-grey-dark;
      }
    }
  }
}

.radio-group.traditional {
  .radio-button {
    display: flex;
    align-items: center;
    min-height: $spacing-biggest;
    position: relative;
    margin-bottom: $spacing-big;

    input {
      @include hiddenElementVisibleForScreenReaders();
    }

    label {
      display: block;
      font-weight: 400;
      margin-left: $spacing-biggest + $spacing-smallest;

      &::before {
        @include pseudo(absolute, '');
        border: solid 1px $color-brand-primary;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        left: 0;
        top: 0;
      }

    }
    &.checked {
      label {
        &::before {
          background-color: $color-brand-primary;
        }

        &::after {
          @include pseudo(absolute, '');
          background-color: $color-brand-primary;
          border: solid 7px $color-white;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          top: 1px;
          left: 1px;
        }
      }
    }
  }
}
