@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

$buttonHeight: 55px;
$buttonAnimationHeight: 200px;

.button {
  background-color: $color-brand-secondary;
  border: none;
  border-radius: 2px;
  color: $color-white;
  font-weight: 700;
  height: $buttonHeight;
  width: 100%;
  margin: $spacing-small 0;
  padding: 0 $spacing-biggest;
  text-transform: uppercase;

  &.no-margin-top {
    margin-top: 0;
  }

  @media screen and (min-width: $screen-sm) {
    width: auto;
  }

  &:hover {
    background-color: $color-brand-primary;
  }

  &:disabled,
  &.disabled {
    cursor: auto;
    background-color: $color-grey-light;
  }

  &.secondary {
    background-color: $color-white;
    border: solid 1px $color-brand-primary;
    color: $color-brand-secondary;

    &:hover {
      border-color: $color-brand-primary;
      color: $color-brand-primary;
    }

    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
      background-color: $color-grey-background;
      border-color: $color-grey-light;
      color: $color-grey-light;
    }
  }

  &.secondary {
    background-color: $color-white;
    border: solid 1px $color-brand-primary;
    font-weight: bold;
    color: $color-brand-secondary;
    width: auto;

    &:hover {
      color: $color-brand-primary;
      border: solid 1px $color-brand-primary;
    }
  }

  &.large {
    width: 100%;
  }

  &.unstyled {
    background: none;
    color: $color-brand-secondary;
    font-weight: 400;
    height: auto;
    width: auto;
    padding: 0;
    text-transform: none;
  }

  &.wide-content {
    padding: 1em;
  }

  &.active {
    background: $color-brand-secondary;
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background-color: $color-brand-primary;
      border-radius: 50%;
      @include animation(scaleOut 300ms 1 ease-out);
      opacity: 0;
    }
  }

  @include keyframes(scaleOut) {
    0% {
      opacity: 1;
      top: 50%;
      right: 50%;
      left: 50%;
      bottom: 50%;
    }
    100% {
      opacity: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin: $spacing-small 0;

  .button {
    margin: 0 $spacing-smallest;
    width: 50%;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
