@import '../../../common/styles/deko-styles/variables/variables';
@import '~deko-common-components/lib/styles/mixins';

@media screen and (min-width: $screen-sm) {
  .sidebar-wrapper {
    display: flex;
    aside {
      background-color: $color-white;
      flex: 1 1;

      header {
        padding-right: $spacing-small;
      }

      .full-height-col {
        @include sticky();
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        margin-top: -$spacing-small;
        height: fit-content;


        .sidebar-content {
          flex-grow: 2;
        }
      }

      .product-summary {
        margin-bottom: $spacing-biggest;
        .line {
          padding-right: $spacing-small;
          padding-left: $spacing-small;
        }
      }

      .criteria {
        padding: 0 $spacing-smallest;
        margin-bottom: $spacing-biggest;

        p {
          color: $color-grey-medium;
        }

        strong {
          color: $color-grey-dark;
        }
      }
    }
  }
  .has-top-bar {
    .full-height-col {
      // top-bar padding + top-bar text height + a little spacing to make it smooth (1px)
      top: ($spacing-biggest * 2 ) + $spacing-smallest + 0.075;
    }
  }
  .full-height-col {
    top: 0.075em;
  }

}
