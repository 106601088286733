// ==== Font sizes ====
// The px values are only accurate if the base font of the element is 16px
// https://kyleschaeffer.com/development/css-font-size-em-vs-px-vs-pt-vs/
$font-size-xxxl: 1.750em; // 28px - H1 Only
$font-size-xxl: 1.250em; // 20px - H2, H3, H4, H5, H6
$font-size-lg: 1.125em; // 18px - Values in text fields, placeholders
$font-size-the-base: 1em; // 16px - Had to name this the base so it wouldn't confuse Bootstrap.
$font-size-sm: .875em; // 14px - Privacy policy details, product summary, contact details

// ==== Font Family ====
$font-family: 'Roboto', Helvetica, Arial, sans-serif;
