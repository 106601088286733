@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.address-input-group {
  input[type='radio'] {
    @include prefix(appearance, none, (webkit, moz));
    border: none;
    background: none;
  }

  .current-address-message {
    margin: $spacing-small 0 0;
  }

  .enter-manually {
    margin: 0;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .address-input .summary-wrapper .summary-container {
    button {
      color: $color-brand-secondary-accessible-on-grey;

      svg g {
        stroke: $color-brand-secondary-accessible-on-grey
      }
    }
  }

  .address-button-container {
    margin-top: $spacing-smallest;
    .button {
      display: flex;
      align-items: center;
      font-weight: 400;
      padding: 0 15px;
      text-transform: none;
      width: 100%;
    }

    p {
      font-weight: 600;
    }

    .cross-wrapper {
      background-color: $color-white;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
      height: 36px;
      width: 36px;
      position: relative;
      margin-right: $spacing-small;

      .cross {
        @include cross($color-brand-secondary, 1.2em, 3px, absolute);
        @include prefix('transform', rotate(45deg), ('webkit'));
        left: 8px;
        top: 8px
      }
    }
    .action {
      display: inline-block;
      position: absolute;
      margin-top: 0.3em;
      padding-left: 11px;
      text-decoration: underline;
      color: $color-brand-secondary;
    }

    &.has-errors {
      p, .button {
        color: $color-error;
      }

      .button {
        background-color: transparent;
        border: 1px solid $color-error;
      }

      .cross-wrapper {
        background-color: $color-error;
      }
      .cross {
        @include cross($color-white, 1.2em, 3px, absolute);
      }
    }
  }
}
