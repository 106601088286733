@import './../deko-styles/variables/variables';
@import '../../../../node_modules/deko-common-components/lib/styles/mixins';

.expandable-container {
  position: relative;

  .expandable-read-more-title {
    text-align: left;
    text-decoration: underline;
    margin: 0 0 0 $spacing-small;
    &:hover {
      text-decoration: none;
    }

    &::before {
      @include chevron($color-brand-secondary, right, 4px, 4px, 1px);
      left: 0;
      top: 6px;
    }

    &.opened::before {
      @include chevron($color-brand-secondary, down, 4px, 4px, 1px);
      left: 0;
      top: 6px;
    }
  }
}
