@import '~deko-common-components/lib/styles/mixins';

.pull-out-button-container {
  position: relative;
}

.pull-out-button {
  cursor: pointer;
  display: none;
  position: absolute;
  z-index: 2;

  &:before {
    @include pseudo(absolute, '');
    display: block;
    top: 0;
    left: 0;
    z-index: 3;
  }
}

.children-visible .pull-out-button {
  display: none;
}

.children-not-visible .pull-out-button {
  display: block;
  position: fixed;
}
.force-show.children-visible .pull-out-button{
  display: block;
}
