@import '../variables/variables';

.modal-container.document-view {
  .close-button-outer-wrapper {
    background-color: $color-white;
    position: absolute;
    top: 0;
    left: -12%;
    width: 12%;
    display: block;
    cursor: pointer;
    border-radius: 0;

    .close-button-wrapper {
      display: flex;
      justify-items: center;
      border-radius: 0;
      height: 2.5em;
      width: 100%;
      padding: 0;

      .close-button {
        position: relative;
        margin: 0 auto;
      }
    }
  }

  .modal-content {
    background-color: $color-white;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 90%;

    .main {
      flex-grow: 1;
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  @media screen and (min-width: $screen-sm) {
    position: relative;
    z-index: initial;

    .modal-background-overlay {
      display: none;
    }
    .close-button-outer-wrapper {
      display: none;
    }

    .modal-content {
      display: block;
      background: none;
      position: relative;
      width: 100%;
      max-width: 100%;
      z-index: 0;

      .main {
        overflow-y: auto;
      }
    }
  }
}
