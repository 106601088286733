@charset "utf-8";

@import '../../common/styles/deko-styles/variables/variables';

@import '~deko-common-components/lib/styles/reset';
@import '~deko-common-components/lib/styles/utilities';

@import '../../common/styles/deko-styles/base';
@import '../../common/styles/deko-styles/common-deko-styles';

/**
 * Component specific styling
 */
@import 'components/form/MagiFileUpload';
@import 'components/form/MagiIdModal';
@import 'components/form/MagiModal';
@import 'components/form/MagiNumberInput';

body {
  overflow-x: hidden;
}

:focus {
  outline: lightskyblue dotted 2px;
}
