@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border-radius: 2px;
    width: 80%;
    padding: $spacing-small $spacing-small 0 $spacing-small;

    @media screen and (min-width: $screen-sm) {
      max-width: 500px;
      width: 50%;
    }
  }

  .modal-background-overlay {
    background-color: black;
  }
}
