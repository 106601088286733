@import '../../../common/styles/deko-styles/variables/variables';

.amendment-confirmation-page,
.amendment-declined-page {

  h1 {
    margin: 0;
  }

  .amendment-page-copy {
    margin: $spacing-small 0;

  }

  .checkbox-input {
    margin-top: $spacing-biggest;
    margin-bottom: $spacing-biggest;
  }

  .consent-section {
    border-bottom: 1px solid $color-grey-light;
    margin: $spacing-small 0;
  }

  .product-summary {
    &:last-child {
      margin-bottom: $spacing-smallest;
    }
  }

  .button {
    margin-top: 0;
  }

  .instore-submit-modal {
    h1 {
      padding-bottom: $spacing-smallest / 2;
      margin-bottom: $spacing-smallest / 2;
      border-bottom: 1px solid $color-grey-light;
    }
  }
}
