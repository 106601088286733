@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.text-input,
.number-input {
  width: 100%;

  input {
    @include prefix('appearance', textfield, ('webkit', 'moz'));
    border: 1px solid $color-grey-light;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: $font-size-lg;
    font-weight: lighter;
    line-height: 1.2;
    padding: $spacing-smallest;
    height: 55px;
    width: 100%;
    min-width: 1px; // To make it appear the right way in Firefox

    @include placeholder {
      color: $color-grey-light;
      font-weight: 400;
    }

    &:focus {
      border-color: $color-brand-primary;
      outline: none;

      @include placeholder {
        color: transparent;
      }
    }
  }

  &.has-error {
    .label {
      display: none;

      &.error {
        display: inline;
      }
    }

    input {
      border-color: $color-error;
      &:focus {
        border-color: $color-brand-primary;
      }
    }

    &.has-tooltip .field input:focus + .tooltip button {
      border-left-color: $color-brand-primary;
    }

    .error {
      color: $color-error;
    }
  }
}

.addons {
  display: inline;
  font-size: $font-size-xxl;
  position: relative;

  .number-input {
    padding-left: 1.5em;
    padding-right: 3.8em;
  }

  .left-addon,
  .right-addon {
    color: $color-grey-light;
    line-height: 1.6;
    padding: 0.9em 0.8em;
    pointer-events: none;
    position: absolute;
    z-index: 1;

    @media screen and (min-width: $screen-sm) {
      padding: 0.6em 0.8em 0.5em;
    }
  }

  .right-addon {
    right: 0;
  }
}
