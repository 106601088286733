@import '../../../common/styles/deko-styles/variables/variables';
@import '~deko-common-components/lib/styles/mixins';

.problem-pages {
  main {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-biggest;

    .img {
      display: inline-flex;
      justify-content: center;
      width: 100%;

      svg {
        max-width: 100%;
        justify-self: center;
      }
    }

    .not-found-image,
    .problem-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bottom {
      position: relative;
      display: flex;
      flex-direction: column;

      button {
        width: 100%;
      }

      @media screen and (min-width: $screen-sm) {
        .button {
          width: auto;
          align-self: center;
        }
      }
    }
  }

  &.no-js-page {
    main {
      margin: 0 10px;

      @media screen and (min-width: $screen-sm) {
        margin: 7em 15px;
      }

      @media screen and (min-width: $screen-lg) {
        align-items: center;
      }
    }

    .graphic {
      align-self: center;
    }

    .logo {
      align-items: flex-end;
      align-self: center;
      background: none;
      margin-right: 10px;
    }
  }
}
