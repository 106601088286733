@import '~deko-common-components/lib/styles/mixins';
@import '../../deko-styles/variables/variables';

footer {
  background-color: $color-white;
  width: 100%;
  @include sticky();
  bottom: auto;

  .footer {
    padding: $spacing-small;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    .delimiter {
      margin: 0 $spacing-smallest;
    }

    p {
      color: $color-grey-medium;
      font-size: $font-size-sm;
      line-height: 1.43;
      width: 100%;
    }

    .img {
      position: absolute;
      right: 30px;
      bottom: 10%;

      @media screen and (min-width: $screen-sm) {
        left: $spacing-small;
        right: auto;
        top: 25%;
      }
    }

    ul {
      margin: 0;
    }

    li {
      display: block;
      list-style: none;
      margin-left: 0;

      a {
        margin: 0;
      }

      &:first-of-type a {
        margin-left: 0;
      }

      &:last-of-type a {
        margin-right: 0;
      }
    }
    @media screen and (min-width: $screen-sm) {
      padding-bottom: $spacing-biggest;
      position: relative;
      flex-direction: column;
      padding-left: ($spacing-big*5) + $spacing-small;

      ul {
        margin: 0 auto 0 0;
      }

      li {
        display: inline;
      }
    }
  }
}
