@import '../variables/variables';
@import '../../../../../node_modules/deko-common-components/lib/styles/mixins';

.privacy-policy-list {
  margin-top: $spacing-big;

  p {
    margin-top: $spacing-smallest;
  }

  .lender-privacy-section {
    position: relative;
    li {
      margin: 0 0 $spacing-smallest 0
    }
  }
}
