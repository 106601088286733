// ==== Gutter ====
$grid-gutter-width: 20px;
@media (min-width: $screen-md) {
  $grid-gutter-width: 30px;
}

// ==== Container Sizes ====
// Small screen / tablet
$container-tablet:             initial;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet;

// Medium screen / desktop
$container-desktop:            (990px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md:                 initial;

// Large screen / wide desktop
$container-large-desktop:      (1230px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop;
