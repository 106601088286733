@import '~deko-common-components/lib/styles/mixins';
@import '../../../common/styles/deko-styles/variables/variables';


.promotion-provider {
   margin: 0 0.7em;

  .promotion-no-interest {
    display: flex;
    border: 1px solid #133742;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 14px;
    margin-bottom: 2.5em;
    background: #F2F2F2;
    max-width: 300px;

    .pound-sign {
      margin-right: 10px;
    }

    .promotion {
      flex: 1;
      line-height: 1.4;
    }
  }
}
