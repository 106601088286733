@import '~deko-common-components/lib/styles/mixins';
@import '../../../../common/styles/deko-styles/variables/variables';

.id-upload-modal .modal-container {
  &.modal-delete {
    p {
      font-size: $font-size-xxl;
      margin-bottom: $spacing-biggest;
    }

    button {
      width: auto;

      @media screen and (min-width: $screen-sm) {
        width: 100%;
        max-width: 160px;
      }
    }
  }

  .modal-content {
    img.modal-preview-image {
      max-width: 100%;
    }

    @media screen and (min-width: $screen-sm){
      max-width: 400px;
    }
  }
}
