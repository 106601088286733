@import '../../../common/styles/deko-styles/variables/variables';

.fulfil-page {
  .form-section {
    margin-bottom: $spacing-biggest;
  }

  .close-button-outer-wrapper {
    display: none;
  }
}
