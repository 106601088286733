@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &.full-page {
    @include fixed(0, 0, 0, 0, 4);
    flex-direction: column;
    background-color: $color-grey-background;
  }

  &.button-loader {
    flex-direction: row;
    position: relative;

    .graphic-wrapper {
      position: absolute;
      left: 0;
    }
  }

  h1 {
    font-size: $font-size-lg;
  }

  .text-wrapper {
    .loader-title {
      @include animation(
                      typeText 2s linear infinite,
                      textFadeOut 2s ease-in infinite
      );
      display: block;
      margin: 0 auto;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  @include keyframes(typeText) {
    0% {
      width: 0;
    }
    50%, 100% {
      width: 100%;
    }
  }

  @include keyframes(textFadeOut) {
    0%, 90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
