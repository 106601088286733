@import '../../../common/styles/deko-styles/variables/variables';
@import '~deko-common-components/lib/styles/mixins';

h2 {
    border-bottom: 1px solid $color-brand-primary;
    margin-bottom: $spacing-small;
}

.button {
    cursor: pointer;
}

.personal-loans-tac {
    font-size: $font-size-sm;
    margin-bottom: $spacing-big;
}