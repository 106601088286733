@import '../../../common/styles/deko-styles/variables/variables';
@import '~deko-common-components/lib/styles/mixins';

.canvas-wrapper{
  margin-top: $spacing-big;
  text-align: center;
}
.complete-image,
.decline-image,
.refer-image {
  display: flex;
  justify-content: center;
  margin-top: $spacing-biggest;
  margin-bottom: $spacing-biggest;

  svg {
    max-width: 100%;

    .detail-stroke {
      stroke: $color-positive;
    }

    .detail-fill {
      fill: $color-positive
    }
  }
}

.decline-page {
  .bureau-info,
  .lending-decisions-info {
    .bureau-info-section,
    .lending-decisions-section {
      margin-bottom: $spacing-small;

      p,
      &.lending-decisions p:first-child {
        margin-bottom: 0;
      }
    }
  }

  .bureau-info {
    p {
      margin-left: $spacing-small;
    }
  }

  .contact-details {
    margin-left: $spacing-small;

    p {
      font-size: $font-size-sm;
      margin: inherit;
    }
  }
}

.bureau-section-item {
  position: relative;

  .button {
    margin: 0 0 $spacing-small $spacing-small;
    text-align: left;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &::before {
      @include chevron($color-brand-secondary, right, 4px, 4px, 1px);
      left: 0;
      top: 7px;
    }

    &.opened::before {
      @include chevron($color-brand-secondary, down, 4px, 4px, 1px);
      left: 0;
      top: 6px;
    }
  }
}
