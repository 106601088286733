@import '~deko-common-components/lib/styles/mixins';
@import '../../../common/styles/deko-styles/variables/variables';

.product-summary {
  @media screen and (min-width: $screen-sm) {
    margin-top: 0;
  }

  &.sticky {
    @include sticky();
    top: 88px;
  }

  .line {
    font-size: $font-size-sm;
    line-height: $font-size-lg;
    display: inline-block;
    width: auto;
    clear: both;
    padding: 0;
    margin-bottom: $spacing-smallest;

    &:last-of-type {
      border-bottom: none;
    }
  }
}
