@import '../../../../common/styles/deko-styles/variables/variables';

.modal {
  padding: $spacing-small;

  @media screen and (min-width: $screen-sm) {
    padding: $spacing-big;
  }

  .button-group {
    flex-direction: column-reverse;

    .button {
      width: 100%;
      margin: 0 0 $spacing-smallest;
    }

    .secondary {
      margin: 0;
      width: 100%;
    }

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;

      .button {
        margin: 0 $spacing-smallest;
        width: 50%;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.modal-overlay {
  background-color: $color-black;
}
