@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.checkbox-input {
  line-height: 2.5;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    &[type='checkbox'] {
      @include hiddenElementVisibleForScreenReaders();
      background: none;
      border: none;
      &:focus + label:before {
        border-color: $color-brand-primary;
      }
    }
  }

  label {
    line-height: 1.5em;
    margin: 0 0 0 2.8em;

    a {
      font-weight: 500;
    }

    &:before {
      content: '';
      border: 1px solid $color-grey-light;
      background-color: $color-white;
      border-radius: 50%;
      box-sizing: border-box;
      position: absolute;
      height: 34px;
      width: 34px;
      top: 0;
      left: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .field {
    display: flex;
    margin-top: 0;
    position: relative;
    align-items: center;
    min-height: 40px;

    &:hover {
      cursor: pointer;
    }
  }


  &.checked label:before {
    background-color: $color-brand-secondary;
  }

  &.checked label:after {
    @include pseudo(absolute, '');
    border: solid $color-white;
    border-right: 2px;
    border-top: 2px;
    height: 6px;
    width: 12px;
    transform: rotate(-45deg);
    top: 11px;
    left: 10px;
  }

  &.no-padding-bottom {
    margin-bottom: 0;
  }

  input:focus + label:before {
    border-color: $color-brand-primary;
  }

  &.has-error {
    input:focus + label:before,
    label:before {
      border-color: $color-error;
    }

    .error {
      color: $color-error;
      position: absolute;
      top: -36px;
      left: 0;
    }
  }

  .field-as-error {
    color: $color-error;

    &:before {
      border: 1px solid $color-error;
    }
  }
}
