@import '../variables/variables';
@import '../../../../../node_modules/deko-common-components/lib/styles/mixins';

.circle-container {
  width: 50px;
  height: 50px;
  margin-bottom: $spacing-big;

  .button-loader & {
    width: 30px;
    height: 30px;
    margin-bottom: 0;
  }

  .circle-dot {
    &::before {
      background-color: $color-brand-primary;
      .button-loader & {
        background-color: $color-white;
      }
    }
  }
}
