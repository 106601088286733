@import '../../../../common/styles/deko-styles/variables/variables';
@import '~deko-common-components/lib/styles/mixins';

/// variables to determine the height of the dropzone
$dropzone-height: 200px;

.upload-image-area {
  position: relative;

  &.has-error {
    .label {
      display: none;

      &.error {
        display: inline;
      }
    }

    .error {
      color: $color-error;
    }

    .validation-symbol {
      display: none;
    }
  }
}

.enabled {
  .dropzone-override:hover a {
    text-decoration: none;
  }
}

.dropzone-override {
  width: 100%;
  border: 2px dashed $color-grey-light;
  border-radius: 4px;
  background-color: $color-white;
  position: relative;
  margin-bottom: 20px;
  min-height: $dropzone-height;

  input[type="file"] {
    display: block !important;
    @include hiddenElementVisibleForScreenReaders();
  }

  &:first-of-type {
    margin-top: 10px;
  }

  .upload-placeholder {
    .upload-placeholder-content,
    img.image-preview {
      transform: translate(-50%, -50%);
      @include absolute($top: ($dropzone-height/2), $left: 50%);
      span:first-child {
        display: block;
        text-align: center;
      }
    }

    img.image-preview {
      max-height: 90%;
      max-width: 90%;
    }
  }

  &.has-errors {
    border-color: $color-error;
  }
}

.actions-bar {
  @include absolute($bottom: 0);
  height: 1.5em;
  background-color: $color-grey-light;
  width: 100%;
  text-align: right;
  opacity: 0.7;
  padding-top: 3px;
  i svg {
    height: 1em;
    margin: 0 3px;
  }
}

.pdf-page {
  max-width: 100%;

  canvas {
    max-width: 100%;
    height: auto !important; // react-pdf set the height inline so this is the only way to overwrite it. I know it's ok but I feel dirty.
  }

  .react-pdf__Page__textContent {
    max-width: 100%;
    overflow: hidden;
  }
}
