@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

header {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: $spacing-small $spacing-small 1.15em $spacing-small;
  z-index: 3;

  .img.logo {
    height: 40px;
    width: auto;
    max-width: 140px;
    object-fit: contain;
  }

  @media screen and (min-width: $screen-sm) {
    align-self: flex-end;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    padding: $spacing-small 0;
    @include sticky();
    top: 0;

    .img.logo {
      max-width: 200px;
      height: 77px;
    }
  }
}
