@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.pull-out-button {
  background-color: $color-black;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  margin: -17.5px auto 0 auto;
  left: calc(50% - 18px);

  &:before {
    background-color: $color-white;
    height: 13px;
    width: 36px;
  }

  &:after {
    left: 12px;
  }

  &.down:after {
    @include chevron($color-white, down, .5em, .5em, 2px);
    top: 17px;
  }

  &.up:after {
    @include chevron($color-white, up, .5em, .5em, 2px);
    top: 21px;
  }

  &.left:after {
    @include chevron($color-white, left, .5em, .5em, 2px);
  }

  &.right:after {
    @include chevron($color-white, right, .5em, .5em, 2px);
  }
}

.children-not-visible .pull-out-button {
  top: 80px;
}
.force-show.children-visible .pull-out-button{
  bottom: -23px;
}

@media screen and (min-width: $screen-sm) {
  .children-visible .pull-out-button {
    display: none;
  }
}
