@import '../../../common/styles/deko-styles/variables/variables';
.legal-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  margin-top: $spacing-biggest;

  .faq-page,
  .privacy-policy-page,
  .security-statement-page,
  .tos-page,
  .what-is-credit-search-page {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-biggest;

    h2 {
      margin-bottom: $spacing-small;
    }

    ol {
      margin-bottom: $spacing-biggest;

      li {
        margin-bottom: $spacing-smallest;
        margin-left: $spacing-small;
        .question {
          margin-bottom:$spacing-smallest / 2;
          display:block;
        }
      }
    }

    .img.logo {
      object-fit: contain;
    }

    .header {
      display: flex;
      justify-content: flex-end;
    }

    .contact-details {
      margin-left: $spacing-small;
      p {
        margin: inherit;
      }
    }

    .bureau-info {
      margin-bottom: $spacing-smallest;
      margin-top: $spacing-smallest;

      .bureau-info-section {
        p {
          margin: inherit;
        }
      }
    }

    .agencies-info {
      margin-left: $spacing-smallest;
      margin-right: $spacing-smallest;
    }
  }

  .address {
    padding-left: 2.4em;
    p {
      margin-bottom: 0;
    }
  }
}
