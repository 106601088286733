@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

$input-height: 55px;

.multi-input-wrapper {
  width: 100%;
  .field {
    .multi-input-group {
      border-radius: 0;
      height: $input-height;

      input {
        border: solid $color-grey-light;
        border-width: 1px 0;
        border-radius: 0;
        text-align: center;
        min-width: 1px;

        background-image: linear-gradient($color-grey-light, $color-grey-light);
        background-size: 1px 35px;
        background-position: left 0 top 10px;
        background-repeat: no-repeat;

        &:first-of-type {
          background-image: none;
          border-left-width: 1px;
        }

        &:last-of-type {
          border-right-width: 1px;
        }

        &:focus {
          background-image: none;
          border: 1px solid $color-brand-primary;
        }
        &:focus + input {
          background-image: none;
        }
      }
    }
  }
  input {
    &.day, &.month, &.year {
      @include placeholder {
        text-transform: uppercase;
      }
    }
  }

  &.has-error {
    .field .multi-input-group input {
      border-color: $color-error;
      min-width: 0; //firefox bug with flex elements
      &:focus {
        border-color: $color-brand-primary;
      }
    }
  }
}
