@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.select-input {
  position: relative;

  select {
    @include prefix('appearance', none, ('webkit', 'moz'));
    background-color: $color-white;
    cursor: pointer;
    height: 55px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $color-grey-light;
    border-radius: 2px;
    position: relative;
    padding: $spacing-smallest;
    font-size: $font-size-the-base;
    line-height: 1.2;

    &::-ms-expand {
      visibility: hidden;
    }

    &:focus {
      outline:none;
      border-color: $color-brand-primary;
    }
  }

  &:after {
    @include triangle($color-brand-secondary, 'down', 7px);
    cursor: pointer;
    bottom: 22px;
    pointer-events: none;
    right: $spacing-smallest;
  }

  &.has-error {
    .label {
      display: none;

      &.error {
        display: inline;
      }
    }

    select {
      border-color: $color-error;
      &:focus {
        border-color: $color-brand-primary;
      }
    }

    &.select-input:after {
      border-top-color: $color-error;
    }

    .error {
      color: $color-error;
    }
  }

  .options {
    color: $color-black;
    font-size: $font-size-xxl;
    font-family: $font-family;

    &.placeholder {
      color: $color-grey-light;
    }
  }
}
