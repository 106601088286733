@import '~deko-common-components/lib/styles/mixins';

.circle-container {
  position: relative;
  display: flex;
  justify-items: center;

  .circle-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 20%;
      height: 20%;
      border-radius: 100%;
      animation: circle-fade 1.75s infinite ease both;

      .button-loader & {
        width: 20%;
        height: 20%;
      }
    }

    &.circle-dot2 {
      transform: rotate(51deg);
      &::before {
        animation-delay: -1.75s;
      }
    }
    &.circle-dot3 {
      transform: rotate(102deg);
      &::before {
        animation-delay: -1.5s;
      }
    }
    &.circle-dot4 {
      transform: rotate(154deg);
      &::before {
        animation-delay: -1.25s;
      }
    }
    &.circle-dot5 {
      transform: rotate(205deg);
      &::before {
        animation-delay: -1s;
      }
    }
    &.circle-dot6 {
      transform: rotate(257deg);
      &::before {
        animation-delay: -.75s;
      }
    }
    &.circle-dot7 {
      transform: rotate(307deg);
      &::before {
        animation-delay: -.5s;
      }
    }
    &.circle-dot8 {
      transform: rotate(360deg);
      &::before {
        animation-delay: -.225s;
      }
    }
  }

  @include keyframes(circle-fade) {
    0%, 9%, 100% {
      opacity: 0;
      transform: scale(.6);
    }
    10% {
      opacity: 1;
      transform: scale(1);
    }
  }
}
