@import '~deko-common-components/lib/styles/mixins';
@import 'variables/variables';

@-ms-viewport{
  width: device-width;
}

body {
  background-color: $color-grey-background;
}

body, button, input, h1, h2, h3, h4, h5, h6, p {
  font: $font-size-the-base $font-family;
  @include prefix(font-smoothing, antialiased, webkit moz ms);
  color: $color-black;
  font-weight: 400;
}

a,
.link {
  color: $color-brand-secondary-accessible-on-grey;
  text-decoration: underline;
}

.has-errors-message {
  margin-top: $spacing-big;

  a {
    color: $color-error;
  }
}

a:hover {
  text-decoration: none;
}

fieldset {
  border: none;
}

h1 {
  font-size: $font-size-xxxl;
  font-weight: 300;
  margin-bottom: $spacing-smallest;
  letter-spacing: -1px;
}

h2 {
  font-size: $font-size-xxl;
  font-weight: 300;
}

h3 {
  font-size: $font-size-xxl;
  font-weight: 700;
  margin-bottom: .3em;
}

h4,
h5,
h6 {
  font-size: $font-size-xxl;
  font-weight: 700;
  margin-bottom: .3em;
}

label,
.label {
  color: $color-black;
  font-size: $font-size-the-base;
  font-weight: 700;

  &.error {
    font-weight: 400;
  }
}

legend {
  @include hiddenElementVisibleForScreenReaders();
}

p {
  line-height: 1.4;
  margin-bottom: $spacing-smallest;
}

li {
  margin-bottom: $spacing-smallest;
}


ul {
  margin-left: 1.5em;
}

ul li {
  margin-left: $spacing-small;
  list-style: disc;
}


.font-small {
  font-size: $font-size-sm;
}

.bold {
  font-weight: 700;
}

.form-group {
  margin-top: $spacing-biggest;
  position: relative;
}

.field {
  margin-top: $spacing-smallest;
}

.has-errors-message {
  color: $color-error;
}

.accessible-error-summary{
  @include hiddenElementVisibleForScreenReaders();
}

.standfirst {
  font-size: $font-size-xxl;
}

@media screen and (min-width: $screen-sm) {
  .validation-symbol.invalid {
    @include cross($color-error, 0.8em, 2px, absolute);
    bottom: 21px;
    left: -20px;
  }
}

.font-small-mobile {
  font-size: $font-size-sm;
  @media screen and (min-width: $screen-sm) {
    font-size: $font-size-the-base;
  }
}

.no-margin-left {
  margin-left: 0 !important;
}

.margin-bottom-large {
  margin-bottom: $spacing-biggest;
}

.margin-top-large {
  margin-top: $spacing-biggest;
}
