@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

$sidebar-offset-sm: 468px;
$sidebar-offset-md: 494px;
$sidebar-offset-lg: 614px;

.tooltip {
  line-height: 1.43;
  display: inline;


  ul {
    margin-bottom: $spacing-big;
  }

  li {
    margin-left: $spacing-smallest;
  }

  .button {
    background-color: $color-brand-secondary;
    border: solid 1px $color-brand-secondary;
    border-radius: 3px;
    color: $color-white;
    height: 40px;
    margin: 7px 0 7px 10px;
    outline: none;
    padding: 8px 0 0 0;
    top: 8px;
    width: 40px;

    @media screen and (min-width: $screen-sm) {
      top: 0;
    }

    &:hover,
    &:focus {
      background-color: $color-brand-primary;
      border-color: $color-brand-primary;
    }
  }

  &.visible {
    .button {
      background-color: $color-brand-primary;
      border-color: $color-brand-primary;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &::before {
        @include triangle($color-brand-primary, down, 20px, 8px);
        bottom: -9px;
        right: -1px;
      }

      @media screen and (min-width: $screen-sm) {
        border-radius: 3px 0 0 3px;

        // Tooltip arrow sm <
        &:before {
          @include triangle($color-brand-primary, right, 20px, 10px);
          left: 39px;
          top: -1px;
        }
      }
    }

    .message {
      background-color: $color-white;
      border: 1px solid $color-brand-primary;
      border-radius: 2px;
      color: $color-black;
      margin-top: $spacing-smallest;
      padding: $spacing-small;
      position: relative;

      // Message arrow < xs
      &::before {
        @include triangle($color-brand-primary, down, 20px, 8px);
        top: -1px;
        right: 0;
      }

      &::after {
        @include triangle($color-grey-background, down, 18px, 7px);
        top: -1px;
        right: 2px;
      }

      @media screen and (min-width: $screen-sm) {
        margin-top: 0;
        position: absolute;
        left: $sidebar-offset-sm; // This is to force the tooltip to the sidebar
        top: 0;
        width: 58%; // This is the width of the sidebar col
        z-index: 4;

        &:before,
        &:after {
          z-index: 4;
        }

        // Message arrow sm <
        &:before {
          @include triangle($color-brand-primary, right, 25px, 11px);
          left: -1px;
          top: 2px;
        }

        &:after {
          @include triangle($color-grey-background, right, 24px, 11px);
          left: -2px;
          top: 3px;
        }
      }

      // Message position
      @media screen and (min-width: $screen-md) {
        left: $sidebar-offset-md; // This is to force the tooltip to the sidebar
        width: 82%; // This is the width of the sidebar col
      }

      @media screen and (min-width: $screen-lg) {
        left: $sidebar-offset-lg; // This is to force the tooltip to the sidebar
      }

      .example-image {
        border: 1px solid;
        position: relative;
        margin: $spacing-smallest 0 $spacing-small;

        &:last-of-type {
          margin-bottom: 0;
        }

        img {
          width: 100%;
        }

        &:before {
          @include pseudo(absolute, '');
          border-radius: 50%;
          height: 3.2em;
          right: -1em;
          top: -1.5em;
          width: 3.2em;
        }

        &.example-correct {
          border-color: $color-positive;

          &:before {
            background-color: $color-positive;
          }

          &:after {
            @include check($color-white, 1em, 4px);
            top: -10px;
            right: 3px;
          }
        }

        &.example-incorrect {
          border-color: $color-error;
          color: $color-error;

          .cross {
            @include cross($color-white, 1.5em, 4px, absolute);
            top: -10px;
            right: -3px;
          }

          &:before {
            background-color: $color-error;
          }
        }
      }
    }
  }

  &.bottom-tooltip {
    @media screen and (min-width: $screen-sm) {
      &.visible .message {
        top: auto;
        bottom: -12px;

        &::before {
          top: auto;
          bottom: 5px;
        }

        &::after {
          top: auto;
          bottom: 6px;
        }
      }
    }
  }
}

// Styling for the parent
.has-tooltip {
  .field {
    position: relative;

    @media screen and (min-width: $screen-sm){
      display: flex;
    }

    input, .quick-fill {
      width: calc(100% - 50px);

      @media screen and (min-width: $screen-sm){
        width: 100%;
      }
    }
  }

  // Input type specific styling
  // Checkbox
  &.checkbox-input.has-tooltip {
    flex-wrap: wrap;

    label {
      margin-right: 45px;
    }

    .field {
      margin-top: 0;
    }

    .tooltip {
      .button {
        background-color: $color-brand-secondary;
        border-radius: 3px;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: 0;
        top: 0;
        right: 0;

        .disabled & {
          border-color: $color-grey-light;

          .icon svg g {
            fill: $color-brand-primary;
            opacity: .8;
          }
        }

        &:hover,
        &:focus {
          background-color: $color-brand-primary;
          border-color: $color-brand-primary;

          .disabled & {
            background-color: $color-brand-secondary;
            border-color: $color-grey-light;
          }
        }
      }

      &.visible {
        .button {
          background-color: $color-brand-primary;
          border-color: $color-brand-primary;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          &::before {
            @include triangle($color-brand-primary, down, 20px, 8px);
            bottom: -9px;
            right: -1px;
            z-index: 1;
          }

          @media screen and (min-width: $screen-sm) {
            border-radius: 3px 0 0 3px;
            &::before {
              @include triangle($color-brand-primary, right, 20px, 8px);
              left: 39px;
              top: -1px;
            }
          }
        }
      }
    }
  }

  // Text input
  @media screen and (min-width: $screen-sm) {
    &.text-input .addons {

      .left-addon,
      .right-addon {
        top: 0;
      }
    }
  }

  // Multi input
  &.text-input.multi-input-wrapper {
    width: 80%;

    .field {
      display: flex;
      flex-wrap: wrap;

      button {
        position: absolute;
        top: 0;
        right: 0;
      }

      .multi-input-group {
        width: calc(100% - 45px);

        input {
          width: 100%;
          min-width: 0; //firefox bug with flex elements
        }

        .multi-input:last-of-type {
          border-right: none;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }

  // Counter input
  &.counter-input {
    .radio-group.modern.counter-buttons {
      border-right: 1px solid $color-grey-light;
    }

    .counter-display-input {
      margin-right: 45px;
    }

    .tooltip {
      margin-bottom: $spacing-biggest;

      button {
        position: absolute;
      }
    }
  }
}
