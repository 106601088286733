@import '../../../common/styles/deko-styles/variables/variables';
@import '~deko-common-components/lib/styles/mixins';

.page {
  display: flex;
  /**
  The flex direction is wrong. The media query logic is the other way around and the whole style has been based off
  of that. Fixing the media query would have required a full restyling so we decided to just set it as a default. RIP
 */
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: space-between;

  &.disabled {
    .form-group {
      &::before {
        @include pseudo(absolute, '');
        top: 0;
        bottom: -10px; // Workaround because tooltips currently overflow
        right: 0;
        left: 0;
        background-color: $color-grey-background;
        opacity: $disabled-opacity;
        z-index: 1;
      }
    }
  }

  .sticky-drawer-container {
    height: 100%;
  }

  footer {
    align-self: flex-end;

    @media screen and (min-width: $screen-sm) {
      align-self: auto;
    }
  }

  .background-color-amazon-yellow{
    background-color: #FF9900;
  }

  .background-color-amazon-yellow:hover{
    background-color: #f5b247;
  }

  .form-container {
    margin-bottom: $spacing-biggest;
  }

  .main-container {
    display: flex;
    align-items: stretch;
    flex-grow: 1;

    @media screen and (min-width: $screen-sm) {
      margin-top: $spacing-small;
    }
  }
}
