// ==== Brand colours ====
$color-black: #133742; // Muir
$color-white: #fff;
$color-brand-primary: #12B59F; // Glas
$color-brand-secondary: #0E8474; // Glas (A2)
$color-brand-secondary-accessible-on-grey: #0D7866;
$color-brand-primary-light: #D3E5E2;

// ==== Neutral Colours ====
$color-grey-background: #F1F1F1;
$color-grey-light: #B2B2B2;
$color-grey-light-light: #e0e0e0; // Liath
$color-grey-medium: #67797e;
$color-grey-dark: #405C65;
$color-grey-contrast: #cccccc;

// ==== Utility Colours ====
$color-error: #BA0324;
$color-positive: #00860A;
$color-neutral: #0057BD;
$color-error-light: #F0D1D5;

// ==== Opacity ====
$disabled-opacity: 0.2;

// ==== Misc Colors ====
$color-dark: #3c3c3c !default;
$color-white: #fff !default;
$color-background: #efeeee !default;
$color-input-background: #fff !default;
$color-input-border: #d4d1ca !default;
$color-divider: #9b9b9b !default;
$color-divider-logos: #bcbbbb !default;
$color-placeholder-text: #9b9b9b !default;
$color-block-quote: #9b9b9b !default;
$color-error: #d0021b !default;
