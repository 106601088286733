@import '~deko-common-components/lib/styles/mixins';
@import '../variables/variables';

.drawer-container {
  background-color: $color-white;
  margin-bottom: $spacing-biggest;
  width: 100%;

  @media screen and (min-width: $screen-sm) {
    background: none;
    border-bottom: none;
    margin-bottom: 0;
  }

  header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .children {
    // This is so the children don't hide behind the header. I know it's ugly.
    // If this is changed, we need to change the hardcoded height sent into StickyPullOut.tsx
    // and the hack in TopStatusBar.scss.
    margin-top: 80px;

    .current-summary-wrapper {
      padding: 0 $spacing-small $spacing-small;
    }
  }

  &.children-not-visible {
    header {
      border-bottom: 5px solid $color-black;
    }
  }

  &.children-visible.force-show {
    border-bottom: 5px solid $color-black;
    height: auto;
    min-height: 85px; // Header height + border width
    left: 0;
    overflow-y: visible;
    position: fixed;
    width: 100%;
    z-index: 2;
  }

  &.children-none {
    header {
      height: 83px;
      border-bottom: 2px solid $color-black;
    }
  }

}

.no-summary-content .sticky-pull-out {
  border-bottom: none;

  .pull-out-button {
    display: none;
  }
}
