@import '~deko-common-components/lib/styles/mixins';

.modal-container {
  @include fixed(0, 0, 0, 0, 1000);
  z-index: 1000;

  .modal-background-overlay {
    cursor: pointer;
    opacity: 0.8;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
  }

  .modal-content {
    z-index: 1002;
  }

  .modal-close {
    z-index: 1003;
  }
}
