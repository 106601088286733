@import '../variables/variables';

.progress-div {
  color: $color-grey-contrast;
  border-top: 1px solid $color-brand-secondary;

  @media screen and (min-width: $screen-sm) {
    border: none;
  }

  &.scrolled {
    color: $color-grey-contrast;
    a {
      color: $color-white;
    }
  }

  .progress {
    background-color: $color-brand-secondary;
  }
}
